import React, { Component } from 'react';
import logo from './trap.png';
import './App.css';
import Main from './Main.js';
import Tooltip from '@material-ui/core/Tooltip';
import { Grid } from '@material-ui/core';

class App extends Component{
  constructor(props) {
    super(props);
    this.state = {
      showMain: false,
    };
  }

  goMain = () => {
    this.setState({showMain:true})
  }
  render() {
    if(this.state.showMain == true)
    {
      return(
        <Main />
      )
    }
    return (
      <div className="App">
          <img src="/kitchen.gif" style={{position:'fixed', top:'0px', left:'0px', width:'100%', height:'100%', zIndex:'-1'}}/>
          <div style={{float:'right'}}>
          <Grid
              container
              direction="column"
              spacing="0"
              wrap="nowrap"
            >
              <h style={{fontFamily:"Times New Roman", fontSize:'80px', color:'white'}}><b>
                L&I Plumbing Inc.
              </b>
              </h>
              <p style={{fontFamily:"Times New Roman", fontSize:'40px', color:'white'}}>
                For all your plumbing needs.
              </p>
              <Tooltip title="Click to enter site" arrow>
              <a
                className="App-link"
                href="#"
                onClick={()=>this.goMain()}
                style={{fontFamily:"Times New Roman", fontSize:'35px'}}
              >
                Enter
              </a>
              </Tooltip>
        </Grid>
        </div>
      </div>
    );
  }
}

export default App;
