import React, { Component, useEffect, useState } from 'react';
import logo from './trap.png';
import './App.css';
import AppBar from '@material-ui/core/AppBar';
import ToolBar from '@material-ui/core/ToolBar';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import ContactsIcon from '@material-ui/icons/Contacts';
import Tooltip from '@material-ui/core/Tooltip';
import CardMedia from '@material-ui/core/CardMedia';
import Carousel from 'react-material-ui-carousel'
import {Paper} from '@material-ui/core'
import { Grid } from '@material-ui/core';
import Main from './Main.js';
import About from './About.js';

class Contact extends Component{
  constructor(props) {
    super(props);
    this.state = {
      showMain: false,
      showAbout:false,
    };
  }

  render() {
    if(this.state.showMain == true)
    {
      return(
        <Main />
      )
    }
    else if(this.state.showAbout == true)
    {
      return(
        <About />
      )
    }

    return (
      <div className="Contact">
      <AppBar position="static" style={{background:'#ffffff'}}>
         <ToolBar variant="dense">
          <img src="/logo2.png" style={{maxHeight:'80px', float:'left'}}/>
          <div style={{marginLeft:'auto'}}>
            <Tooltip title="Home" arrow>
            <IconButton style={{color:"black"}} size="large" aria-label="home" component="span" onClick={() => this.setState({showMain:true})}>
              <HomeIcon fontSize='large'/>
            </IconButton>
            </Tooltip>
            <Tooltip title="About" arrow>
            <IconButton style={{color:"black"}} size="large" aria-label="about" component="span" onClick={() => this.setState({showAbout:true})}>
              <InfoIcon fontSize='large'/>
            </IconButton>
            </Tooltip>
            <Tooltip title="Contact" arrow>
            <IconButton style={{color:"black"}} size="large" aria-label="contact" component="span">
              <ContactsIcon fontSize='large'/>
            </IconButton>
            </Tooltip>
          </div>
        </ToolBar>
      </AppBar>
      <img src="/drip.jpeg" style={{position:'fixed', top:'50px', left:'0px', width:'100%', height:'100%', zIndex:'-1'}}/>
      <div class="transboxTwo">
        <h2 style={{textAlign:'center'}}>Lester Balkissoon</h2>
        <h2 style={{textAlign:'center'}}>Florida State Certified Plumbing Contractor (License #: CFC 1429983)</h2>
        <h2 style={{textAlign:'center'}}>Phone Number: 321-299-3166</h2>
        <h2 style={{textAlign:'center', color:'#003366'}}><a href="mailto:Lester@liplumbinginc.com">Email: Lester@liplumbinginc.com</a></h2>
      </div>
      </div>
    );
  }
}

export default Contact;
