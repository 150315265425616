import React, { Component, useEffect, useState } from 'react';
import logo from './trap.png';
import './App.css';
import './index.css';
import AppBar from '@material-ui/core/AppBar';
import ToolBar from '@material-ui/core/ToolBar';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import ContactsIcon from '@material-ui/icons/Contacts';
import Tooltip from '@material-ui/core/Tooltip';
import CardMedia from '@material-ui/core/CardMedia';
import Carousel from 'react-material-ui-carousel'
import {Paper} from '@material-ui/core'
import { Grid } from '@material-ui/core';
import About from './About.js';
import Contact from './Contact.js';
import { Container } from '@material-ui/core';

class Main extends Component{
  constructor(props) {
    super(props);
    this.state = {
      showAbout: false,
      showContact: false
    };
  }

  render() {
    if(this.state.showAbout == true)
    {
      return(
        <About />
      )
    }
    else if(this.state.showContact == true)
    {
      return(
        <Contact />
      )
    }

    return (
      <div className="Main">
      <AppBar position="static" style={{background:'#ffffff'}}>
         <ToolBar variant="dense">
          <img src="/logo2.png" style={{maxHeight:'80px', float:'left'}}/>
          <div style={{marginLeft:'auto'}}>
            <Tooltip title="Home" arrow>
            <IconButton style={{color:"black"}} size="large" aria-label="home" component="span" >
              <HomeIcon fontSize='large'/>
            </IconButton>
            </Tooltip>
            <Tooltip title="About" arrow>
            <IconButton style={{color:"black"}} size="large" aria-label="about" component="span" onClick={() => this.setState({showAbout:true})}>
              <InfoIcon fontSize='large'/>
            </IconButton>
            </Tooltip>
            <Tooltip title="Contact" arrow>
            <IconButton style={{color:"black"}} size="large" aria-label="contact" component="span" onClick={() => this.setState({showContact:true})}>
              <ContactsIcon fontSize='large'/>
            </IconButton>
            </Tooltip>
          </div>
        </ToolBar>
      </AppBar>
    <Container>
    <div className='primary'>
    <Carousel interval={8000}>
            {
              [<Paper style={{background:'black'}}>
              <Grid
                  container
                  direction="row"
                  spacing="1"
                  wrap="nowrap"
                >
                <img src="/pipes.jpeg" style={{maxHeight:'400px', minWidth:'500px'}}/>
                <img src="/reno.jpeg" style={{maxHeight:'400px', minWidth:'500px'}}/>
                <Grid
                    container
                    direction="column"
                    spacing="0"
                    wrap="nowrap"
                  >
                  <h2 style={{color:'white', textAlign: 'center', marginBottom: '2px'}}>Plumbing Renovations & Service</h2>
                  <h2 style={{color:'white', textAlign: 'center', marginTop: '2px'}}>(Commercial and Residential)</h2>
                  <p style={{color:'white'}}>Single & Multi-Family Buildings</p>
                  <p style={{color:'white'}}>Retirement Facilities</p>
                  <p style={{color:'white'}}>Custom Homes</p>
                  <p style={{color:'white'}}>Hotel Renovations</p>
                  <p style={{color:'white'}}>Apartment and Condominium Community Renovations</p>
                </Grid>
                </Grid>
              </Paper>,
              <Paper style={{background:'black'}}>
              <Grid
                  container
                  direction="row"
                  spacing="1"
                  wrap="nowrap"
                >
                <Grid
                    container
                    direction="column"
                    spacing="0"
                    wrap="nowrap"
                  >
                  <h2 style={{color:'white', textAlign: 'center'}}>Plumbing Service Work</h2>
                  <p style={{color:'white'}}>Sewer Camera Inspections</p>
                  <p style={{color:'white'}}>Sewer & Water Line Repairs</p>
                  <p style={{color:'white'}}>Drain Cleaning & Repairs</p>
                  <p style={{color:'white'}}>Re-pipes</p>
                  <p style={{color:'white'}}>Plumbing Repairs</p>
                </Grid>
                <img src="/workbench.jpeg" style={{maxHeight:'400px', minWidth:'600px'}}/>
                <Grid
                    container
                    direction="column"
                    spacing="0"
                    wrap="nowrap"
                  >
                  <h2 style={{color:'white', textAlign: 'center'}}></h2>
                  <p style={{color:'white'}}>Slab Leaks</p>
                  <p style={{color:'white'}}>Water Heater Replacements and Repairs</p>
                  <p style={{color:'white'}}>Plumbing Fixtures Replacements</p>
                  <p style={{color:'white'}}>Shower Valves Replacements and Repairs</p>
                  <p style={{color:'white'}}>Faucets and Garbage Disposal</p>
                </Grid>
                </Grid>
              </Paper>,
              <Paper style={{background:'black'}}>
                <Grid
                    container
                    direction="row"
                    spacing="1"
                    wrap="nowrap"
                    md="auto"
                  >
                  <Grid
                      container
                      direction="column"
                      spacing="0"
                      wrap="nowrap"
                    >
                    <h2 style={{color:'white', textAlign: 'center'}}>Locations Serviced</h2>
                    <p style={{color:'white', marginBottom: '2px'}}>Orange and Seminole County</p>
                    <p style={{color:'white', marginBottom: '2px', marginTop: '2px'}}>Orlando</p>
                    <p style={{color:'white', marginBottom: '2px', marginTop: '2px'}}>Lake Mary</p>
                    <p style={{color:'white', marginBottom: '2px', marginTop: '2px'}}>Oviedo</p>
                    <p style={{color:'white', marginBottom: '2px', marginTop: '2px'}}>Deltona</p>
                    <p style={{color:'white', marginBottom: '2px', marginTop: '2px'}}>Longwood</p>
                    <p style={{color:'white', marginBottom: '2px', marginTop: '2px'}}>Altamonte Springs</p>
                    <p style={{color:'white', marginBottom: '2px', marginTop: '2px'}}>Casselberry</p>
                    <p style={{color:'white', marginTop: '2px'}}>Maitland</p>
                  </Grid>
                  <img src="/thing.jpeg" style={{maxHeight:'400px', minWidth:'500px'}}/>
                  <img src="/faucet.jpeg" style={{maxHeight:'400px', minWidth:'500px'}}/>
                  </Grid>
              </Paper>]
            }
        </Carousel>
        </div>
        </Container>
        <div>
    <div class="gallery-background">
        <div class="gallery-text" style={{fontSize:'20px', fontFamily:"Comic Sans MS", marginTop: '10%'}}><b>For all your plumbing needs...</b></div>
        <div class="gallery-text" style={{fontSize:'20px', fontFamily:"Comic Sans MS", marginTop: '15%'}}>L & I Plumbing, Inc. specializes in plumbing renovations and repairs as well as new commercial construction and remodel projects across the state of Florida.</div>
        <img src="/Wave.gif" class="galleryLrg" />
    </div>
    </div>
      </div>
    );
  }
}

export default Main;
