import React, { Component, useEffect, useState } from 'react';
import logo from './trap.png';
import './App.css';
import AppBar from '@material-ui/core/AppBar';
import ToolBar from '@material-ui/core/ToolBar';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import ContactsIcon from '@material-ui/icons/Contacts';
import Tooltip from '@material-ui/core/Tooltip';
import CardMedia from '@material-ui/core/CardMedia';
import Carousel from 'react-material-ui-carousel'
import {Paper} from '@material-ui/core'
import { Grid } from '@material-ui/core';
import Main from './Main.js';
import Contact from './Contact.js';

class About extends Component{
  constructor(props) {
    super(props);
    this.state = {
      showMain: false,
      showContact:false,
    };
  }

  render() {
    if(this.state.showMain == true)
    {
      return(
        <Main />
      )
    }
    else if(this.state.showContact == true)
    {
      return(
        <Contact />
      )
    }

    return (
      <div className="About">
      <AppBar position="static" style={{background:'#ffffff'}}>
         <ToolBar variant="dense">
          <img src="/logo2.png" style={{maxHeight:'80px', float:'left'}}/>
          <div style={{marginLeft:'auto'}}>
            <Tooltip title="Home" arrow>
            <IconButton style={{color:"black"}} size="large" aria-label="home" component="span" onClick={() => this.setState({showMain:true})}>
              <HomeIcon fontSize='large'/>
            </IconButton>
            </Tooltip>
            <Tooltip title="About" arrow>
            <IconButton style={{color:"black"}} size="large" aria-label="about" component="span" >
              <InfoIcon fontSize='large'/>
            </IconButton>
            </Tooltip>
            <Tooltip title="Contact" arrow>
            <IconButton style={{color:"black"}} size="large" aria-label="contact" component="span" onClick={() => this.setState({showContact:true})}>
              <ContactsIcon fontSize='large'/>
            </IconButton>
            </Tooltip>
          </div>
        </ToolBar>
      </AppBar>
      <img src="/happy.jpeg" style={{position:'fixed', top:'0px', left:'0px', width:'100%', height:'100%', zIndex:'-1'}}/>
      <div class="transbox">
        <h2 style={{textAlign:'center'}}>About L&I Plumbing Inc. </h2>
        <p>L&I Plumbing Inc. is owned by Lester Balkissoon. Lester has been a plumber for over forty years. With roots in New York, he worked for reputable plumbing companies before owning his own. This transferred when he moved to the state of Florida where he is a Florida State Certified Plumbing Contractor. L&I Plumbing Inc. has a solid foundation built on Lester’s well-rounded knowledge of the plumbing field. It can be ensured that the work provided will be up to all standards and of high-quality. Satisfaction guaranteed!</p>
      </div>
      </div>
    );
  }
}

export default About;
